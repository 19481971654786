/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ya hablamos de Firefox 3.5 la nueva version que esta a punto de lanzarse, cuando hablamos de HTML5, por ahora podemos disfrutar de la ", React.createElement(_components.a, {
    href: "http://www.mozilla.com/en-US/firefox/3.5b4/releasenotes/",
    title: "Firefox 3.5 RC2"
  }, "RC2"), ". En el siguiente video Mike Beltzner, Director de desarrollo de Firefox, nos explica las nuevas caracteristicas de esta versión. Creo que el video queda bastante claro y no hace falta explicar mas."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=k5Zbc-Rg6e8]"), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
